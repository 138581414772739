<script lang="ts" setup>
import {
  Autoplay as SwiperAutoplay,
  Navigation as SwiperNavigation,
} from "swiper/modules";
import type { TypeButton } from "~/types/TypeButton";
import type { TypeStat } from "~/types/TypeStat";
import type { TypeHeaderHomepage } from "~/types/contentful";

defineProps<{
  fields: TypeHeaderHomepage<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"] & { buttons: TypeButton[]; stats: TypeStat[] };
}>();

const loadedContent = useLoadedContent();

const isDark = computed(() => loadedContent.value?.fields.slug === "home");

onMounted(() => {
  const homeHeader: HTMLElement | null = document.querySelector(
    "main .page :first-child",
  );

  const appHeader: HTMLElement | null
    = document.querySelector("section.header");

  if (homeHeader && appHeader) {
    if (isDark.value) {
      homeHeader.style.paddingTop = `calc(1.5rem + ${String(appHeader.offsetHeight)}px)`;

      homeHeader.style.marginTop = `calc(-1 * (1.5rem + ${String(appHeader.offsetHeight)}px))`;

      if (homeHeader.firstElementChild) {
        (homeHeader.firstElementChild as HTMLElement).style.marginTop
          = homeHeader.style.marginTop = `calc(-1 * (1.5rem + ${String(appHeader.offsetHeight)}px))`;
      }
    } else {
      homeHeader.style.removeProperty("paddingTop");

      homeHeader.style.removeProperty("marginTop");

      if (homeHeader.firstElementChild) {
        (homeHeader.firstElementChild as HTMLElement).style.removeProperty(
          "marginTop",
        );
      }
    }
  }
});
</script>

<template>
  <Container
    class="relative overflow-hidden bg-dark-blue-grey-two py-4 text-white lg:bg-center"
    container-class="relative"
  >
    <template #outside>
      <NuxtImg
        v-if="fields.backgroundImage?.fields.file?.url"
        ref="backgroundImage"
        :src="fields.backgroundImage.fields.file.url"
        :alt="fields.backgroundImage.fields.title"
        sizes="sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
        class="absolute size-full object-cover object-right-top"
        loading="lazy"
      />
    </template>
    <header class="grid grid-cols-12 gap-4 py-4 md:pt-7 lg:pt-4">
      <div class="col-span-12 md:col-span-8 lg:col-span-9">
        <div class="header-text">
          <h1>{{ fields.title }}</h1>
          <p class="mt-4 text-2xl max-lg:leading-8 lg:text-4xl">
            {{ fields.subtitle }}
          </p>
          <div class="mt-4 flex lg:mt-6">
            <NuxtLink
              v-for="btn in fields.buttons"
              :key="btn.title"
              class="btn mr-3"
              :class="'btn--' + btn.type"
              :to="btn.url"
            >
              {{ btn.title }}
            </NuxtLink>
          </div>
        </div>
      </div>

      <div class="col-span-12 pt-3 md:col-span-4 lg:col-span-2 lg:col-start-11">
        <div
          v-for="stat in fields.stats"
          :key="stat.title"
        >
          <div class="stat mb-2 border-b border-white/20">
            <h4 class="mb-2">
              {{ stat.title }}
            </h4>
            <p>
              {{ stat.copy }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="relative col-span-12 mt-[120px] hidden after:absolute after:left-0 after:top-[-40px] after:h-px after:w-full after:bg-white/20 after:content-[''] md:flex"
      >
        <div class="me-5 h-[140px]">
          <h4 class="mb-3 whitespace-nowrap">
            Latest Activity
          </h4>
          <div class="nav flex gap-3">
            <div class="button-prev">
              <NuxtIcon
                name="fairr:button-arrow-clear"
                class="size-[40px] rotate-180 cursor-pointer text-white"
              />
            </div>
            <div class="button-next">
              <NuxtIcon
                name="fairr:button-arrow-clear"
                class="size-[40px] cursor-pointer text-white"
              />
            </div>
          </div>
        </div>
        <div class="carousel">
          <ClientOnly>
            <swiper-container
              :modules="[SwiperAutoplay, SwiperNavigation]"
              :slides-per-view="'auto'"
              :space-between="24"
              :rewind="true"
              :navigation="{
                nextEl: '.button-next',
                prevEl: '.button-prev',
              }"
            >
              <swiper-slide
                v-for="slide in fields.activities"
                :key="slide?.fields.title"
                class="rounded-sm"
              >
                <NuxtLink
                  :to="
                    (slide?.fields.button as TypeButton[] | undefined)?.[0]?.url
                  "
                >
                  <div class="grid grid-cols-12 gap-2 p-[12px] text-white">
                    <div class="col-span-12">
                      <TextBadge class="badge--activity-bar rounded-sm">
                        {{
                          slide?.fields.type
                        }}
                      </TextBadge>
                    </div>
                    <div class="col-span-4">
                      <NuxtImg
                        v-if="slide?.fields.image"
                        :src="slide.fields.image.fields.file?.url"
                        :alt="slide.fields.image.fields.title"
                        fit="contain"
                        :width="124"
                        :height="93"
                        loading="lazy"
                      />
                    </div>
                    <div class="col-span-8 pl-1">
                      <div class="font-semibold">
                        {{ slide?.fields.title }}
                      </div>
                    </div>
                  </div>
                </NuxtLink>
              </swiper-slide>
            </swiper-container>
          </ClientOnly>
        </div>
      </div>
    </header>
  </Container>
</template>

<style lang="scss" scoped>
.carousel {
  @apply w-[calc(100%-220px)] 2xl:w-[calc(100%-50px)];
}

swiper-container {
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 140px;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgb(19 22 64 / 0%) 0%,
      rgb(19 22 64 / 49.8%) 40%,
      rgb(19 22 64 / 100%) 100%
    );
    z-index: 2;
  }
}

swiper-slide {
  width: 410px;
  background-color: rgb(255 255 255 / 20%);
}
</style>
